import React, { useEffect, useState } from "react";
import { format, parse } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DoneIcon from "@mui/icons-material/Done";
import Dropzone from "../common/Dropzone";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MarkdownEditor from "../common/MarkdownEditor";
import MenuItem from "@mui/material/MenuItem";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { getAccountType } from "../auth/functions";
import { maxHeight } from "@mui/system";

const SKILLS_EVENT_TYPES = [
    ["CAREERS FAIR","Careers Fair"],
    ["CONFERENCE","Conference"],
    ["COURSE","Course"],
    ["HACKATHON","Hackathon"],
    ["SOCIAL","Social"],
    ["SUMMER SCHOOL","Summer School"],
    ["TALK","Talk"],
    ["WORKSHOP","Workshop"],
    ["COMPETITION","Competition"]
]

const NewSkillEventEditableTextField = ({ label, name, value, setValue, selectValues, errors, ...rest }) => {
    if (selectValues) {
        return (
            <TextField
                size="small"
                label={label}
                variant="filled"
                color="primary"
                select
                value={value}
                onChange={(e) => setValue(name, e.target.value)}
                fullWidth
                errors={errors}
                {...rest}
            >
                {selectValues.map((value) => (
                    <MenuItem key={value[0]} value={value[0]}>
                        {value[1]}
                    </MenuItem>
                ))}
            </TextField>
        );
    } else {
        return (
            <TextField
                size="small"
                label={label}
                variant="filled"
                color="primary"
                value={value}
                onChange={(e) => setValue(name, e.target.value)}
                fullWidth
                errors={errors}
                {...rest}
            />
        );
    }
};

const NewSkillEventImageField = ({ label, name, value, setValue }) => {
    return (
        <Dropzone
            styleOverrides={{
                width: "90%",
                height: "172px", 
                display: "flex",
                justifyContent: "center",
                alignItems: "stretch", 
                overflow: "hidden", 
                border: "2px dashed #ccc",
                marginLeft: "5%",
            }}
            imageProps={{
                style: {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: "block",
                },
                src: value,
                alt: label,
            }}
            onChange={(file) => {setValue(name, file)}}
        />
    );
};

const NewSkillEventDateField = ({ label, name, value, setValue, ...rest }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                mask="__-__-____"
                label={label}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        {...rest}
                    />
                )}
                value={
                    value
                        ? parse(value, "dd-MM-yyyy", new Date())
                        : null
                }
                onChange={(newValue) => {
                    try {
                        setValue(
                            name,
                            format(newValue, "dd-MM-yyyy")
                        );
                    } catch (e) {
                        setValue(name, null);
                    }
                }}
                inputFormat="dd-MM-yyyy"
            />
        </LocalizationProvider>
    );
};

const NewSkillEventPage = () => {
    let location = useLocation();
    let navigate = useNavigate();   
    const [skillEvent, setSkillEvent] = useState({
        name: "",
        organiser: "",
        city: "",
        country: "GB",
        type: "",
        price: "",
        date: "",
        application_deadline: null,
        duration: null,
        description: "",
        link: "",
        posted_date: format(new Date(), "dd-MM-yyyy"),
    });
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState({});
    const [canAddSkillEvent, setCanAddSkillEvent] = useState(false);

    useEffect(() => {
        axiosInstance.get("/api/skillevents/countries/").then((response) => {
            setCountries(response.data);
        });
    }, []);

    useEffect(() => {
            axiosInstance
                .get(`/api/users/me/has_perm/`, { params: { perm: "web.add_skillevent" } })
                .then((response) => {
                    setCanAddSkillEvent(true);
                });
        }, []);

    const setSkillEventValue = (key, value) => {
        setSkillEvent((oldSkillEvent) => ({ ...oldSkillEvent, [key]: value }));
        setErrors((oldErrors) => ({ ...oldErrors, [key]: undefined }));
    };
    
    const setSkillEventDurationValue = (key, value) => {
        setSkillEvent((oldSkillEvent) => {
            const newDuration = { ...oldSkillEvent.duration, [key]: value };
            if (value === 0 || value === "") {
                delete newDuration[key];
            }
            return { ...oldSkillEvent, duration: newDuration };
        });
        setErrors((oldErrors) => ({ ...oldErrors, duration: undefined }));
    };

    const submit = async () => {
        let formData = new FormData();
  
        if (skillEvent.image) { formData.append("image", skillEvent.image); }
        if (skillEvent.duration) { formData.append("duration", JSON.stringify(skillEvent.duration)); }

        Object.entries(skillEvent).forEach(([key, value]) => {
            if (key === "image" || key === "duration") return;
            if (value === null || value === "") return;
            formData.append(key, value);
        });
        axiosInstance
            .post("/api/skillevents/", formData, { 
                headers: {
                "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                navigate(`/skills/${response.data.id}/`);
            })
            .catch((error) => {
                setErrors(error.response.data);
            });
    };

    return (
        <PageContainer dark sx={{gap: '5px'}}>
            {canAddSkillEvent ? ( // options only returns if permission IsAuthenticatedOrReadOnly
                <>
                    <Paper sx={{marginBottom: '3px'}}>
                        <Typography variant="h3" paragraph color="text" textAlign="center" mb={5}>
                            Post new skill event
                        </Typography>
                        <Grid container spacing={1}>
                            <Tooltip title="Submit skill event">
                                <Fab
                                    color="primary"
                                    sx={{
                                        position: "fixed",
                                        bottom: "20px",
                                        right: "40px",
                                        zIndex: 10,
                                    }}
                                    onClick={submit}
                                >
                                    <DoneIcon />
                                </Fab>
                            </Tooltip>
                            <Grid container item xs={7} spacing={1}>
                                <Grid item xs={8}>
                                    <NewSkillEventEditableTextField
                                        label="Name"
                                        name="name"
                                        value={skillEvent.name}
                                        setValue={setSkillEventValue}
                                        required
                                    /> 
                                </Grid>
                                <Grid item xs={10}>
                                    <NewSkillEventEditableTextField
                                        label="Organiser"
                                        name="organiser"
                                        value={skillEvent.organiser}
                                        setValue={setSkillEventValue}
                                        required
                                    /> 
                                </Grid>
                                <Grid item xs={12}>
                                    <NewSkillEventEditableTextField
                                        label="Application form link"
                                        name="link"
                                        value={skillEvent.link}
                                        setValue={setSkillEventValue}
                                        error={errors.link}
                                        helperText={errors.link}
                                        required
                                    /> 
                                </Grid>
                            </Grid>
                            <Grid item xs={5} alignItems={"stretch"}>
                                <NewSkillEventImageField 
                                    label="Image"
                                    name="image"
                                    value={skillEvent.image}
                                    setValue={setSkillEventValue}
                                    error={errors.image}
                                    helperText={errors.image}

                                />
                            </Grid>
                            <Grid item xs={8} md={4} alignContent="flex-end">
                                <NewSkillEventDateField
                                    label="Skill Event Date"
                                    name="date"
                                    value={skillEvent.date}
                                    setValue={setSkillEventValue} 
                                    />
                            </Grid>
                            <Grid item xs={8} md={4} alignContent="flex-end">
                                <FormControl sx={{ position: 'relative' }}>
                                    <FormGroup>
                                        <Stack direction="column" sx={{ paddingTop: "8px",}}>

                                            <InputLabel
                                                disableAnimation
                                                shrink
                                                sx={{ position: 'absolute', 
                                                        top: {xs:'5%', md:'5%'}, 
                                                        left: {xs:'-2%', md:'-3%'},
                                                        fontSize: ".75em",
                                                        
                                                    }}
                                            >
                                                Duration
                                            </InputLabel>
                                            <Stack direction="row" spacing={1}>
                                                <NewSkillEventEditableTextField
                                                    label="Weeks"
                                                    name="weeks"
                                                    value={skillEvent.duration?.weeks ?? ""}
                                                    setValue={setSkillEventDurationValue}
                                                /> 
                                                <NewSkillEventEditableTextField
                                                    label="Days"
                                                    name="days"
                                                    value={skillEvent.duration?.days ?? ""}
                                                    setValue={setSkillEventDurationValue}
                                                /> 
                                                <NewSkillEventEditableTextField
                                                    label="Hours"
                                                    name="hours"
                                                    value={skillEvent.duration?.hours ?? ""}
                                                    setValue={setSkillEventDurationValue}
                                                /> 
                                            </Stack>
                                        </Stack>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8} md={4} alignContent="flex-end">
                                <NewSkillEventDateField
                                    label="Application deadline"
                                    name="application_deadline"
                                    value={skillEvent.application_deadline}
                                    setValue={setSkillEventValue} 
                                    required
                                    />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <NewSkillEventEditableTextField
                                    label="Type"
                                    name="type"
                                    value={skillEvent.type}
                                    setValue={setSkillEventValue}
                                    selectValues={SKILLS_EVENT_TYPES}
                                    required
                                /> 
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <NewSkillEventEditableTextField
                                    label="Price"
                                    name="price"
                                    value={skillEvent.price}
                                    setValue={setSkillEventValue}
                                    required
                                /> 
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NewSkillEventEditableTextField
                                    label="City"
                                    name="city"
                                    value={skillEvent.city}
                                    setValue={setSkillEventValue}
                                /> 
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <NewSkillEventEditableTextField
                                    label="Country"
                                    name="country"
                                    value={skillEvent.country}
                                    setValue={setSkillEventValue}
                                    required
                                    selectValues={countries}
                                /> 
                            
                            </Grid>
                            
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel required>Skill Event Description</InputLabel>
                                    <div style={{ paddingTop: "50px" }}>
                                        <MarkdownEditor
                                            value={skillEvent.description}
                                            onChange={(event, editor) =>
                                                setSkillEventValue("description", editor.getData())
                                            }
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                </>
            ) : (
                <Paper sx={{marginBottom: '3px'}}>
                    <Typography variant="h3" paragraph color="text" textAlign="center" mb={5}>
                        If you are SpaceCareers staff, Please log in to post a new skills event
                    </Typography>
                </Paper>
            )}
        </PageContainer>
    );
};
export default NewSkillEventPage;
