import React, { useCallback, useEffect, useRef, useState } from "react";

import SkillEventCard from "./SkillEventCard";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import { CustomInfiniteScroll as InfiniteScroll } from "../common/CustomInfiniteScroll";
import PageContainer from "../common/PageContainer";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";
import { getAccountType } from "../auth/functions";

// import Stack from "@mui/material/Stack";

const SkillEventSearchPage = () => {
    const [skillEvents, setSkillEvents] = useState([]);
    const [activeSearchParams, setActiveSearchParams] = useState([]);
    const [filteredSkillEvents, setFilteredSkillEvents] = useState([]);
    const nextData = useRef("/api/skillevents/");   
    const [options, setOptions] = useState({}); 
    const [hasMore, setHasMore] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    let [searchOptions, setSearchOptions] = useState([]);
    const [canAddSkillEvent, setCanAddSkillEvent] = useState(false);

    const fetchMoreData = useCallback(() => {
        axiosInstance.get(nextData.current).then((response) => {
            setSkillEvents((items) => items.concat(response.data.results));

            var next = "";
            if (response.data.next) {
                let next_queries = response.data.next.split("?");
                next = next_queries[next_queries.length - 1];
                nextData.current = "/api/skillevents/?" + next;
                setHasMore(true);
            } else {
                nextData.current = undefined;
                setHasMore(false);
            }
        });
    }, []);

    useEffect(() => {
        fetchMoreData();
    }, [fetchMoreData]);


    useEffect(() => {
            axiosInstance.get("/api/skillevents/types/").then((response) => {
                setSearchOptions(response.data.filter( f => f !== "")); // when events dont have a type it returns "" as a filter
            });
    }, []);

    useEffect(() => {
        if (activeSearchParams.length === 0) {
            setFilteredSkillEvents(skillEvents);
            setTotalItems(skillEvents.length);
            return;
        } else {
            let new_list = skillEvents.filter((skillEvent) => 
                activeSearchParams.includes(skillEvent.type)
            )
            setFilteredSkillEvents(new_list)
            setTotalItems(new_list.length);
        }
    }, [activeSearchParams, skillEvents]);

    useEffect(() => {
        axiosInstance.options("/api/skillevents/").then((response) => {
            setOptions(response.data.actions.POST);
        });
    }, []);

    useEffect(() => {
            axiosInstance
                .get(`/api/users/me/has_perm/`, { params: { perm: "web.add_skillevent" } })
                .then((response) => {
                    setCanAddSkillEvent(true);
                });
        }, []);

    return (
        <>
            {canAddSkillEvent ? (
                <Link component={RouterLink} to={`/skills/new`}>
                    <Tooltip title="Add a new skill">
                        <Fab
                            color="primary"
                            sx={{
                                position: "fixed",
                                bottom: "20px",
                                right: "40px",
                                zIndex: 10,
                            }}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </Link>
            ) : null}
            <PageContainer>
                <Grid container>
                    <Grid item xs={12}>
                        <Paper sx={{ padding: "20px" }}>
                                <Typography variant="h4" color="primary">
                                    Skills
                                </Typography>
                                <Typography variant="h6" sx={{paddingTop:"5px"}}>Upcoming opportunities to develop your skills for the space sector</Typography>
                                {/* <Typography variant="h6">By Type</Typography> */}
                                {/* <Grid item container xs={12} sx={{ justifyContent:"center", paddingBottom:"5%" }}>
                                    {searchOptions.map((option) => (
                                        <Grid item xs={12} sm={4} md={3} lg={2} key={option} sx={{ justifyContent:"center" }}>
                                        {activeSearchParams.includes(option) ? (
                                            <Button onClick={() => setActiveSearchParams(activeSearchParams.filter(param => param !== option))}
                                            sx={{
                                                backgroundColor: "#f2f2f2",
                                                    color: "pink",
                                                    width: "90%",
                                                    textTransform: "capitalize",
                                                    margin: { xs: "5px", md: "5%" },
                                                    }}>
                                                    {option}
                                                </Button>
                                            ) : (
                                                <Button onClick={() => setActiveSearchParams([...activeSearchParams, option])}
                                                sx={{
                                                    backgroundColor: "#f2f2f2",
                                                    color: "black",
                                                    width: "90%",
                                                    textTransform: "capitalize",
                                                    margin: { xs: "5px", md: "5%" },
                                                    }}>
                                                    {option}
                                                    </Button>
                                                    )}
                                        </Grid>
                                    ))}
                                </Grid> */}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle"
                            color="primary"
                            align="center"
                            sx={{
                                display: "block",
                                padding: 0,
                                paddingBottom: "20px",
                                paddingTop: "20px",
                            }}
                        >
                            {totalItems} skills opportunities
                        </Typography>
                        <InfiniteScroll
                            dataLength={filteredSkillEvents.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            >
                            <Grid container spacing={2}>
                                {filteredSkillEvents.map((skillEvent) => (
                                    <Grid item sm={12} xs={12} key={skillEvent.id}>
                                        <SkillEventCard skillEvent={skillEvent} />
                                    </Grid>
                                ))}
                            </Grid>
                        </InfiniteScroll>
                    </Grid>
                </Grid>
            </PageContainer>
        </>
    );
};

export default SkillEventSearchPage;
