import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
} from "react-share";
import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { getAccountType, isLoggedIn, isStaff } from "../auth/functions";

import AlarmIcon from "@mui/icons-material/Alarm";
import Analytics from "./Analytics";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Card from "@mui/material/Card";
import CategoryIcon from "@mui/icons-material/Category";
import Container from "@mui/material/Container";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import EditableTextField from "../common/EditableTextField";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import JobAnalysis from "./JobAnalysis";
import JobBufferDialog from "./JobBufferDialog";
import Link from "@mui/material/Link";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MDEditor from "@uiw/react-md-editor";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import OutlinedInput from "@mui/material/OutlinedInput";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import PaymentsIcon from "@mui/icons-material/Payments";
import PlaceIcon from "@mui/icons-material/Place";
import Popover from "@mui/material/Popover";
import Rating from "@mui/material/Rating";
import ReactDOMServer from "react-dom/server";
import SaveIcon from "@mui/icons-material/Save";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Skeleton from "@mui/material/Skeleton";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Stack from "@mui/material/Stack";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import UndoIcon from "@mui/icons-material/Undo";
import UpdateIcon from "@mui/icons-material/Update"
import _ from "lodash";
import axiosInstance from "../axiosInstance";
import { parse } from "date-fns";
import useMediaQuery from "@mui/material/useMediaQuery";
import { usePrompt } from "../common/ReactRouterBlocker";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";

var pluralize = require("pluralize");

const tourSteps = [
    {
        disableBeacon: true,
        content:
            "You can click into text fields to edit their content. Click away or click the Done button when you're finished, or click undo to discard your changes.",
        target: ".job-title > .editable-text-field",
        placement: "right-start",
    },
    {
        disableBeacon: true,
        content: "When you've finished editing, be sure to save your changes.",
        target: ".MuiSpeedDial-fab",
        placement: "left-end",
    },
];

const JobPage = ({ user, addBannerNotification }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let navigate = useNavigate();
    let params = useParams();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [job, setJob] = useState([]);
    const [edits, setEdits] = useState({});
    const [options, setOptions] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const [jobTypes, setJobTypes] = useState([]);
    const [jobCategories, setJobCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error404, setError404] = useState(false);
    const [runJoyride, setRunJoyride] = useState(false);
    const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [expiredReport, setExpiredReport] = useState(false);
    const embedUrl = window.location.origin + "/embed" + window.location.pathname;

    const isDirty = useMemo(() => {
        return (
            Object.keys(edits).length > 0 &&
            Object.keys(edits).some((key) => edits[key] !== job[key])
        );
    }, [edits]);

    const embedLink = useMemo(() => {
        return window.location.origin + "/embed" + window.location.pathname;
    }, []);

    usePrompt(
        "Your changes have not been saved. Are you sure you want to leave this page?",
        isDirty
    );

    useEffect(() => {
        loadJobInfo();
    }, []);

    const submitEdits = useCallback(() => {
        axiosInstance
            .patch(`/api/jobs/${params.jobId}/`, edits)
            .then((response) => {
                setJob(response.data);
                setEdits({});
                enqueueSnackbar("Job updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating job.", { variant: "error" });
            });
    }, [edits]);

    useEffect(() => {
        axiosInstance.options("/api/jobs/").then((response) => {
            setOptions(response.data.actions.POST);
        });
        axiosInstance.get("/api/jobs/currencies/").then((response) => {
            setCurrencies(response.data);
        });
        axiosInstance.get("/api/jobs/types/").then((response) => {
            setJobTypes(response.data);
        });
        axiosInstance.get("/api/jobs/categories/").then((response) => {
            setJobCategories(response.data);
        });
    }, []);

    useEffect(() => {
        // remove any keys from edits where the value is undefined
        if (Object.values(edits).some((e) => e === undefined)) {
            setEdits((oldEdits) => {
                let newEdits = { ...oldEdits };
                Object.keys(newEdits).forEach((key) => {
                    if (newEdits[key] === undefined) {
                        delete newEdits[key];
                    }
                });
                return newEdits;
            });
        }
    }, [edits]);

    const loadJobInfo = () => {
        axiosInstance
            .get(`/api/jobs/${params.jobId}/`)
            .then((response) => {
                setJob(response.data);
                setLoading(false);
                if (!response.data?.expired && !response.data?.private) {
                    window.posthog.capture("Saw job page", {
                        jobId: params.jobId,
                        employerId: response.data.employer.id,
                    });
                }
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    setError404(true);
                }
            });
    };

    const handleExpiredReport = () => {
        axiosInstance
            .post("/api/send-slack-message/", params.jobId)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    setExpiredReport(true)
                    enqueueSnackbar("Thank you for notifying the team", { variant: "success" });
                } else {
                    setExpiredReport(false)
                    enqueueSnackbar("Error updating job.", { variant: "error" });

                }
            })
    }

    const makePublicAction = (snackbarId) => (
        <>
            <Button size="small" color="secondary" onClick={makeJobPublic}>
                Make public
            </Button>
        </>
    );

    useEffect(() => {
        if (job?.private) {
            const key = enqueueSnackbar("This job is private.", {
                action: makePublicAction,
                preventDuplicate: false,
                persist: true,
                variant: "warning",
            });
            return () => {
                closeSnackbar(key);
            };
        }
    }, [job]);

    useEffect(() => {
        addBannerNotification(
            isDirty ? (
                <Typography align="center">
                    You have unsaved changes.
                    <Link
                        onClick={submitEdits}
                        sx={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: theme.palette.highlight.main,
                        }}
                    >
                        Save changes
                    </Link>
                </Typography>
            ) : undefined,
            "dirty"
        );
        return () => {
            addBannerNotification(undefined, "dirty");
        };
    }, [isDirty, submitEdits]);

    useEffect(() => {
        addBannerNotification(
            Object.keys(job).length && job?.can_edit ? (
                <React.Fragment>
                    <Typography align="center">
                        You can edit this job.
                        <Link
                            onClick={() => {
                                setRunJoyride(true);
                            }}
                            sx={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                color: theme.palette.highlight.main,
                            }}
                        >
                            Show me how
                        </Link>
                    </Typography>
                </React.Fragment>
            ) : undefined,
            "ownership"
        );
        return () => {
            addBannerNotification(undefined, "ownership");
        };
    }, [job]);

    useEffect(() => {
        addBannerNotification(
            job?.verified === undefined || job?.verified
                ? undefined
                : "This job has not yet been manually verified by a member of staff. Once this job has been verified, any further jobs will be automatically verified.",
            "verified"
        );
        return () => {
            addBannerNotification(undefined, "verified");
        };
    }, [job]);

    const pluralizeOrOmit = (noun, count, addTrailingSpace = false) => {
        if (!count) {
            return "";
        } else {
            return pluralize(noun, count, true) + (addTrailingSpace ? " " : "");
        }
    };

    const formatDuration = (duration) => {
        return duration?.permanent
            ? "Permanent"
            : pluralizeOrOmit("year", duration?.years, true) +
                  " " +
                  pluralizeOrOmit("month", duration?.months, true) +
                  " " +
                  pluralizeOrOmit("week", duration?.weeks);
    };

    const durationStringified = useMemo(() => {
        return formatDuration(job?.duration);
    }, [job]);

    const makeJobPublic = () => {
        axiosInstance
            .patch(`/api/jobs/${params.jobId}/`, { private: false })
            .then((response) => {
                setJob(response.data);
                enqueueSnackbar("Job updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating job.", { variant: "error" });
            });
    };
    const makeJobPrivate = () => {
        axiosInstance
            .patch(`/api/jobs/${params.jobId}/`, { private: true })
            .then((response) => {
                setJob(response.data);
                enqueueSnackbar("Job updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating job.", { variant: "error" });
            });
    };
    const duplicateJob = () => {
        const jobDetails = Object.assign({}, job);
        jobDetails.employer_id = job.employer.id;
        delete jobDetails.id;
        delete jobDetails.employer;
        delete jobDetails.private;
        delete jobDetails.paid;
        delete jobDetails.posted_date;
        delete jobDetails.employer;
        delete jobDetails.country_display;
        delete jobDetails.expired;
        delete jobDetails.saved;
        navigate("/jobs/new", { state: { job: jobDetails } });
    };

    const structuredJSON = useMemo(() => {
        return {
            "@context": "https://schema.org/",
            "@type": "JobPosting",
            title: job.title,
            description: ReactDOMServer.renderToStaticMarkup(
                <MDEditor.Markdown source={job?.description} />
            ),
            datePosted: job?.posted_date
                ? new Date(parse(job?.posted_date, "dd-MM-yyyy", new Date())).toISOString()
                : undefined,
            validThrough:
                job?.application_deadline && !job?.rolling_deadline
                    ? new Date(
                          parse(job?.application_deadline, "dd-MM-yyyy", new Date())
                      ).toISOString()
                    : null,
            employmentType: job?.part_time ? "Part time" : "Full time",
            hiringOrganization: { "@type": "Organization", name: job?.employer?.name },
            jobLocation: {
                "@type": "Place",
                address: {
                    "@type": "PostalAddress",
                    streetAddress: "",
                    addressLocality: job?.city,
                    addressRegion: "",
                    postalCode: "",
                    addressCountry: job?.country,
                },
            },
        };
    }, [job]);

    const largeScreen = useMediaQuery(theme.breakpoints.up("md"));


    const switchDeadlineType = () => {
        if (job?.can_edit) {
            if ("rolling_deadline" in edits) {
                setEdits({
                    ...edits,
                    rolling_deadline: !edits?.rolling_deadline,
                }) 
            } else {
               setEdits({
                    ...edits,
                    rolling_deadline: !job?.rolling_deadline,
                }) 
            }
        }        
    }

    const open = Boolean(anchorEl);
    const expiredAriaInfo = open ? 'simple-popover' : undefined;


    return (
        <PageContainer error404={error404} maxWidth="lg">
            <Helmet>
                {job?.title ? <title>{job?.title + " - SpaceCareers.uk"}</title> : null}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={job?.title} />
                <meta name="twitter:image" content={job?.employer?.logo} />
                <meta name="twitter:site" content="@spacecareersuk" />
                <meta name="og:image" content={job?.employer?.logo} />
                <meta
                    name="twitter:description"
                    content={`${job?.job_type} - ${durationStringified} - ${job?.city}, ${job?.country_display} `}
                />
                <script className="structured-data-list" type="application/ld+json">
                    {JSON.stringify(structuredJSON)}
                </script>
            </Helmet>
            {job?.can_edit ? (
                <Joyride
                    autoStart={true}
                    run={runJoyride}
                    steps={tourSteps}
                    disableOverlay
                    disableOverlayClose
                    disableScrolling
                    showProgress
                    showSkipButton
                    continuous
                    callback={(data) => {
                        const { type, action, status } = data;
                        if (action === "close" || status === "finished") {
                            setRunJoyride(false);
                            setJoyrideStepIndex(0);
                        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
                            setJoyrideStepIndex(data.index + (action === ACTIONS.PREV ? -1 : 1));
                        }
                    }}
                    stepIndex={joyrideStepIndex}
                />
            ) : null}
            {job?.can_edit ? (
                <SpeedDial
                    sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 10 }}
                    ariaLabel="SpeedDial"
                    icon={<EditIcon />}
                >
                    <SpeedDialAction
                        key={"save"}
                        icon={<SaveIcon />}
                        tooltipTitle={"Save"}
                        onClick={submitEdits}
                        disabled={!isDirty}
                    />
                    {job.private ? (
                        <SpeedDialAction
                            key={"public"}
                            icon={<LockOpenIcon />}
                            tooltipTitle={"Make public"}
                            onClick={makeJobPublic}
                        />
                    ) : (
                        <SpeedDialAction
                            key={"private"}
                            icon={<LockIcon />}
                            tooltipTitle={"Make private"}
                            onClick={makeJobPrivate}
                        />
                    )}
                    <SpeedDialAction
                        key={"reset"}
                        icon={<UndoIcon />}
                        tooltipTitle={"Reset edits"}
                        onClick={() => setEdits({})}
                        disabled={!isDirty}
                    />
                    <SpeedDialAction
                        key={"duplicate"}
                        icon={<ContentCopyIcon />}
                        tooltipTitle={"Duplicate job"}
                        onClick={duplicateJob}
                    />
                </SpeedDial>
            ) : null}
            <Breadcrumbs
                color={theme.palette.text.primary}
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ paddingTop: { xs: "20px", md: 0 } }}
            >
                <Link underline="hover" color="inherit" href="/home">
                    Home
                </Link>
                <Link underline="hover" color="inherit" href="/jobs/">
                    Jobs
                </Link>
                <Typography color="inherit">
                    {loading ? <Skeleton width={300} /> : `${job.title} at ${job?.employer?.name}`}
                </Typography>
            </Breadcrumbs>
            <Box>
                <Paper sx={{ padding: "20px" }}>
                    {isLoggedIn() && getAccountType() === "candidate" ? (
                        <Tooltip title="Save">
                            <Rating
                                icon={<StarRoundedIcon fontSize="inherit" />}
                                emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
                                sx={{ float: "right" }}
                                value={job.saved ? 1 : 0}
                                max={1}
                                onChange={(e, value) => {
                                    let url =
                                        value === 1
                                            ? `/api/jobs/${params.jobId}/save/`
                                            : `/api/jobs/${params.jobId}/unsave/`;
                                    axiosInstance.post(url).then((response) => {
                                        loadJobInfo();
                                    });
                                }}
                            />
                        </Tooltip>
                    ) : null}
                    {/* TODO re-enable when candidate login is available */}
                    {!isLoggedIn() && false ? (
                        <Tooltip title="Login to save jobs">
                            <StarOutlineIcon sx={{ float: "right", color: "lightgray" }} />
                        </Tooltip>
                    ) : null}
                    {loading ? (
                        <Grid container spacing={5}>
                            <Grid item xs={8}>
                                <Typography variant="h4">
                                    <Skeleton variant="text" />
                                </Typography>
                                <Typography variant="h6">
                                    <Skeleton variant="text" />
                                </Typography>
                                <Typography variant="h6">
                                    <Skeleton variant="text" />
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Skeleton variant="square" width={200} height={200} />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container>
                            {smallScreen ? (
                                <Grid item xs={12}>
                                    <img
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "200px",
                                            objectFit: "scale-down",
                                            margin: "auto",
                                            display: "block",
                                            marginBottom: "20px",
                                        }}
                                        src={job?.employer?.logo}
                                        alt="Employer logo"
                                    />
                                </Grid>
                            ) : null}
                            <Grid item md={8} xs={12}>
                                <span className="job-title">
                                    <EditableTextField
                                        value={"title" in edits ? edits.title : job.title}
                                        onChange={(value) => {
                                            setEdits({ ...edits, title: value });
                                        }}
                                        dirty={
                                            edits?.title !== job.title && edits?.title !== undefined
                                        }
                                        variant="h4"
                                        color="primary"
                                        editable={job?.can_edit}
                                        sx={{ marginBottom: "10px" }}
                                        tooltip="Job Title"
                                    />
                                </span>
                                <Link
                                    underline="hover"
                                    color="text"
                                    variant="h5"
                                    component={RouterLink}
                                    to={`/employers/${job?.employer?.id}`}
                                >
                                    <Typography
                                        color="text"
                                        variant="h5"
                                        sx={{ marginBottom: "10px" }}
                                    >
                                        {job?.employer?.name}
                                    </Typography>
                                </Link>
                                <Stack
                                    direction={!smallScreen ? "row" : "column"}
                                    spacing={2}
                                    sx={{ paddingTop: "24px" }}
                                >
                                    {job?.can_edit || job?.job_type ? (
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Job Type">
                                                <AssignmentIcon color="text" />
                                            </Tooltip>
                                            <EditableTextField
                                                value={
                                                    "job_type" in edits
                                                        ? edits.job_type
                                                        : job.job_type
                                                }
                                                onChange={(value) => {
                                                    setEdits({ ...edits, job_type: value });
                                                }}
                                                dirty={
                                                    edits?.job_type !== job.job_type &&
                                                    edits?.job_type !== undefined
                                                }
                                                variant="Subtitle2"
                                                color="text"
                                                editable={job?.can_edit}
                                                selectValues={jobTypes.map(({job_type_name}) => [
                                                    job_type_name,
                                                    job_type_name,
                                                ])}
                                                sx={{ lineHeight: "26px" }}
                                                tooltip="Job Type"
                                            />
                                        </Stack>
                                    ) : null}
                                    <Stack direction="row" spacing={1}>
                                        <Tooltip title="Job Duration">
                                            <CalendarMonthIcon color="text" />
                                        </Tooltip>
                                        <EditableTextField
                                            format={formatDuration}
                                            value={
                                                "duration" in edits ? edits.duration : job.duration
                                            }
                                            onChange={(value) => {
                                                setEdits({ ...edits, duration: value });
                                            }}
                                            dirty={
                                                edits?.duration !== job.duration &&
                                                edits?.duration !== undefined
                                            }
                                            editable={job?.can_edit}
                                            tooltip="Duration"
                                            durationEditor
                                        />
                                    </Stack>
                                    {job?.can_edit || job?.primary_job_category ? (
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Job Category">
                                                <CategoryIcon color="text" />
                                            </Tooltip>
                                            <EditableTextField
                                                value={
                                                    "category" in edits
                                                        ? edits.category
                                                        : job.category
                                                }
                                                onChange={(value) => {
                                                    setEdits({ ...edits, category: value });
                                                }}
                                                dirty={
                                                    edits?.category !== job.category &&
                                                    edits?.category !== undefined
                                                }
                                                variant="Subtitle2"
                                                color="text"
                                                editable={job?.can_edit}
                                                selectValues={jobCategories.map(({category_id, category_name, category_group_name}) => [
                                                    category_id,
                                                    category_group_name + " - " + category_name,
                                                    category_group_name,
                                                ])}
                                                customSelectValuesDisplay={true}
                                                sx={{ lineHeight: "26px" }}
                                                tooltip="Category"
                                            />
                                        </Stack>
                                    ) : null}
                                    {job?.on_site_remote ? (
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Work style">
                                                <HomeWorkIcon color="text" />
                                            </Tooltip>
                                            <Typography
                                                color="text"
                                                variant="Subtitle2"
                                                sx={{
                                                    lineHeight: "26px",
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {_.kebabCase(job?.on_site_remote)}
                                            </Typography>
                                        </Stack>
                                    ) : null}
                                    <Stack direction="row" spacing={1}>
                                        <Tooltip title="Job Location">
                                            <PlaceIcon color="text" />
                                        </Tooltip>
                                        <EditableTextField
                                            value={"city" in edits ? edits.city : job.city}
                                            onChange={(value) => {
                                                setEdits({ ...edits, city: value });
                                            }}
                                            dirty={
                                                edits?.city !== job.city &&
                                                edits?.city !== undefined
                                            }
                                            variant="Subtitle2"
                                            color="text"
                                            editable={job?.can_edit}
                                            sx={{ lineHeight: "26px" }}
                                            tooltip="City"
                                        />
                                        <Typography
                                            variant="Subtitle2"
                                            color="text"
                                            sx={{
                                                marginLeft: "2px !important",
                                                lineHeight: "26px",
                                            }}
                                        >
                                            ,
                                        </Typography>
                                        <EditableTextField
                                            value={
                                                job.can_edit
                                                    ? "country" in edits
                                                        ? edits.country
                                                        : job.country
                                                    : job?.country_display
                                            }
                                            onChange={(value) => {
                                                setEdits({ ...edits, country: value });
                                            }}
                                            dirty={
                                                edits?.country !== job.country &&
                                                edits?.country !== undefined
                                            }
                                            variant="Subtitle2"
                                            color="text"
                                            editable={job?.can_edit}
                                            selectValues={options?.country?.choices?.map(
                                                (option) => [option.value, option.display_name]
                                            )}
                                            sx={{ lineHeight: "26px" }}
                                            tooltip="Country"
                                        />
                                    </Stack>

                                    {job.can_edit ||
                                    ((edits?.salary_range_lower || job?.salary_range_lower) &&
                                        (edits?.salary_range_upper || job?.salary_range_upper)) ? (
                                        <Stack direction="row" spacing={0}>
                                            <Tooltip title="Salary Range">
                                                <PaymentsIcon color="text" />
                                            </Tooltip>
                                            <EditableTextField
                                                value={
                                                    "salary_range_lower_currency" in edits
                                                        ? edits.salary_range_lower_currency
                                                        : job.salary_range_lower_currency
                                                }
                                                onChange={(value) => {
                                                    setEdits({
                                                        ...edits,
                                                        salary_range_lower_currency: value,
                                                        salary_range_upper_currency: value,
                                                    });
                                                }}
                                                dirty={
                                                    edits?.salary_range_lower_currency !==
                                                        job.salary_range_lower_currency &&
                                                    edits?.salary_range_lower_currency !== undefined
                                                }
                                                variant="Subtitle2"
                                                color="text"
                                                editable={job?.can_edit}
                                                selectValues={currencies?.map((option) => [
                                                    option,
                                                    option,
                                                ])}
                                                sx={{ marginLeft: "8px", lineHeight: "26px" }}
                                                tooltip="Salary Currency"
                                            />
                                            <EditableTextField
                                                value={
                                                    "salary_range_lower" in edits
                                                        ? edits.salary_range_lower
                                                        : isNaN(job.salary_range_lower) ||
                                                          job.salary_range_lower == null
                                                        ? job.salary_range_lower
                                                        : parseFloat(job.salary_range_lower)
                                                }
                                                onChange={(value) => {
                                                    setEdits({
                                                        ...edits,
                                                        salary_range_lower: value,
                                                    });
                                                }}
                                                dirty={
                                                    edits?.salary_range_lower !==
                                                        job.salary_range_lower &&
                                                    edits?.salary_range_lower !== undefined
                                                }
                                                variant="Subtitle2"
                                                color="text"
                                                editable={job?.can_edit}
                                                sx={{
                                                    marginLeft: "8px",
                                                    lineHeight: "26px",
                                                    minWidth: "50px",
                                                }}
                                                format={(v) =>
                                                    isNaN(v) || v == null
                                                        ? v
                                                        : parseFloat(v).toLocaleString()
                                                }
                                                tooltip="Salary Range Lower Bound"
                                            />
                                            {!job.can_edit &&
                                            job?.salary_range_lower ===
                                                job?.salary_range_upper ? null : (
                                                <React.Fragment>
                                                    <Typography
                                                        variant="Subtitle2"
                                                        color="text"
                                                        sx={{
                                                            marginLeft: "2px",
                                                            marginRight: "2px",
                                                            lineHeight: "26px",
                                                        }}
                                                    >
                                                        -
                                                    </Typography>
                                                    <EditableTextField
                                                        value={
                                                            "salary_range_lower" in edits
                                                                ? edits.salary_range_upper
                                                                : isNaN(job.salary_range_upper) ||
                                                                  job.salary_range_upper == null
                                                                ? job.salary_range_upper
                                                                : parseFloat(job.salary_range_upper)
                                                        }
                                                        onChange={(value) => {
                                                            setEdits({
                                                                ...edits,
                                                                salary_range_upper: value,
                                                            });
                                                        }}
                                                        dirty={
                                                            edits?.salary_range_upper !==
                                                                job.salary_range_upper &&
                                                            edits?.salary_range_upper !== undefined
                                                        }
                                                        variant="Subtitle2"
                                                        color="text"
                                                        editable={job?.can_edit}
                                                        sx={{ lineHeight: "26px", minWidth: "50px" }}
                                                        format={(v) =>
                                                            isNaN(v) || v == null
                                                                ? v
                                                                : parseFloat(v).toLocaleString()
                                                        }
                                                        tooltip="Salary Range Upper Bound"
                                                    />
                                                </React.Fragment>
                                            )}
                                        </Stack>
                                    ) : null}
                                </Stack>
                            </Grid>
                            {smallScreen ? null : (
                                <Grid item xs={4}>
                                    <img
                                        style={{
                                            width: 200,
                                            height: 200,
                                            objectFit: "scale-down",
                                            margin: "auto",
                                            display: "block",
                                        }}
                                        src={job?.employer?.logo}
                                        alt="Employer logo"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Paper>
            </Box>
            <Grid container spacing={2}>
                <Grid item sm={9} xs={12}>
                    <Paper sx={{ padding: "20px" }}>
                        {loading ? (
                            <span>
                                <Typography variant="h4">
                                    <Skeleton variant="text" />
                                </Typography>
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Typography variant="h4">
                                    <Skeleton variant="text" />
                                </Typography>
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                            </span>
                        ) : (
                            <React.Fragment>
                                <Typography variant="h4" color="primary" paragraph>
                                    Job Description
                                </Typography>
                                <EditableTextField
                                    value={
                                        "description" in edits ? edits.description : job.description
                                    }
                                    onChange={(value) => {
                                        setEdits({ ...edits, description: value });
                                    }}
                                    dirty={
                                        edits?.description !== job.description &&
                                        edits?.description !== undefined
                                    }
                                    markdown
                                    editable={job?.can_edit}
                                    tooltip="Description"
                                />
                            </React.Fragment>
                        )}
                    </Paper>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Stack spacing={2}>
                        {loading ? (
                            <Skeleton variant="rectangular" height={100} />
                        ) : (
                            <Card>
                                <Container disableGutters sx={{ padding: "10px" }}>
                                    <Stack spacing={2}>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            color="text"
                                            sx={{ margin: "auto" }}
                                        >
                                            {("rolling_deadline" in edits) ? (
                                                (edits.rolling_deadline) ? (
                                                    <React.Fragment>
                                                        <Tooltip>
                                                            <Badge
                                                                color="primary"
                                                                sx={{ display: "block", minHeight: "24px" }}
                                                                invisible={job?.rolling_deadline}
                                                                variant="dot"
                                                            >
                                                            </Badge>
                                                        </Tooltip>
                                                        <UpdateIcon onClick={switchDeadlineType} title="Switch deadline type" sx={{"&:hover": job?.can_edit? {cursor:"pointer"} : {}}}>
                                                        </UpdateIcon>
                                                        <Typography onClick={switchDeadlineType} title="Switch deadline type" sx={{"&:hover": job?.can_edit? {cursor:"pointer"} : {}}}>Rolling Deadline</Typography>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <Tooltip>
                                                            <Badge
                                                                color="primary"
                                                                sx={{ display: "block", minHeight: "24px" }}
                                                                invisible={!job?.rolling_deadline}
                                                                variant="dot"
                                                            >
                                                            </Badge>
                                                        </Tooltip>
                                                        <AlarmIcon onClick={switchDeadlineType} title="Switch deadline type" sx={{"&:hover": job?.can_edit? {cursor:"pointer"} : {}}}>
                                                        </AlarmIcon>
                                                        <Typography onClick={switchDeadlineType} title="Switch deadline type" sx={{"&:hover": job?.can_edit? {cursor:"pointer"} : {}}}>Deadline:</Typography>
                                                        <EditableTextField
                                                            value={
                                                                "application_deadline" in edits
                                                                    ? edits.application_deadline
                                                                    : job.application_deadline
                                                            }
                                                            onChange={(value) => {
                                                                setEdits({
                                                                    ...edits,
                                                                    application_deadline: value,
                                                                });
                                                            }}
                                                            dirty={
                                                                edits?.application_deadline !==
                                                                    job.application_deadline &&
                                                                edits?.application_deadline !==
                                                                    undefined
                                                            }
                                                            editable={job?.can_edit}
                                                            datePicker
                                                            tooltip="Application Deadline"
                                                        />
                                                    </React.Fragment>
                                                )
                                            ) : (
                                            (job?.rolling_deadline) ? (
                                                <React.Fragment>
                                                    <UpdateIcon onClick={switchDeadlineType} title="Switch deadline type" sx={{"&:hover": job?.can_edit? {cursor:"pointer"} : {}}}>
                                                    </UpdateIcon>
                                                    <Typography onClick={switchDeadlineType} title="Switch deadline type" sx={{"&:hover": job?.can_edit? {cursor:"pointer"} : {}}}>Rolling Deadline</Typography>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <AlarmIcon onClick={switchDeadlineType} title="Switch deadline type" sx={{"&:hover": job?.can_edit? {cursor:"pointer"} : {}}}>
                                                    </AlarmIcon>
                                                    <Typography onClick={switchDeadlineType} title="Switch deadline type" sx={{"&:hover": job?.can_edit? {cursor:"pointer"} : {}}}>Deadline:</Typography>
                                                    <EditableTextField
                                                        value={
                                                            "application_deadline" in edits
                                                                ? edits.application_deadline
                                                                : job.application_deadline
                                                        }
                                                        onChange={(value) => {
                                                            setEdits({
                                                                ...edits,
                                                                application_deadline: value,
                                                            });
                                                        }}
                                                        dirty={
                                                            edits?.application_deadline !==
                                                                job.application_deadline &&
                                                            edits?.application_deadline !==
                                                                undefined
                                                        }
                                                        editable={job?.can_edit}
                                                        datePicker
                                                        tooltip="Application Deadline"
                                                    />
                                                </React.Fragment>
                                            ))}
                                        </Stack>
                                        {/** Job has application link and is not expired */}
                                        {job.link_to_application_form && !job.expired ? (
                                            <Button
                                                variant="contained"
                                                color="highlight"
                                                href={job.link_to_application_form}
                                                target="_blank"
                                                sx={{
                                                    marginRight: "30px !important",
                                                    marginLeft: "30px !important",
                                                    width: "calc(100% - 60px)",
                                                }}
                                                onClick={() => {
                                                    window.posthog.capture(
                                                        "Clicked job apply link",
                                                        {
                                                            jobId: params.jobId,
                                                            employerId: job.employer.id,
                                                        }
                                                    );
                                                }}
                                            >
                                                Apply now!
                                            </Button>
                                        ) : 
                                        (
                                            /** Job is not expired (but may not have application link) */
                                            !job.expired &&
                                            <Typography align="center">
                                            If you apply, please mention that you found this job on
                                            our site
                                            </Typography>
                                        )
                                        }
                                        {/** Job is expired */}
                                        {job.expired ? (
                                            <Button
                                                variant="contained"
                                                color="highlight"
                                                target="_blank"
                                                disabled
                                                sx={{
                                                    marginRight: "30px !important",
                                                    marginLeft: "30px !important",
                                                    width: "calc(100% - 60px)",
                                                }}
                                            >
                                                Job expired
                                            </Button>
                                        ) : null}
                                    </Stack>
                                    <Stack paddingTop="1rem">
                                        {job?.can_edit? 
                                            <Card>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    startIcon={<RemoveCircleIcon />}
                                                    sx={{ width: "100%" }}
                                                    onClick={() => {
                                                        const yesterday = () => {
                                                            let d = new Date();
                                                            d.setDate(d.getDate() - 1);
                                                            const ISODate = d.toISOString().split('T')[0];
                                                            const fullDate = ISODate.split("-");
                                                            return fullDate[2] + "-" + fullDate[1] + "-" + fullDate[0];
                                                        };
                                                        if (job.rolling_deadline) {
                                                            setEdits({
                                                                ...edits,
                                                                rolling_deadline: false,
                                                                application_deadline: yesterday(),
                                                            });
                                                        } else {
                                                                setEdits({
                                                                    ...edits,
                                                                    application_deadline: yesterday(),
                                                                });
                                                        }                                                        
                                                        enqueueSnackbar("Job updated. Remember to save your changes", { variant: "warning" });
                                                    }}
                                                >
                                                Delist advert
                                            </Button>
                                            </Card>
                                        : null}
                                    </Stack>
                                </Container>
                            </Card>
                        )}
                        {loading ? (
                            <Skeleton variant="rectangular" height={100} />
                        ) : (
                            <>
                                {job?.can_edit || job?.expired? (
                                    null
                                ) : (
                                    <>
                                        {expiredReport ? (
                                            <Card
                                                sx={{
                                                    display:'flex',
                                                    justfiyContent:'center',
                                                    backgroundColor:'#43a047',
                                                }}
                                            >
                                                <Typography sx={{textAlign:'center', width:'100%', height:'24px', padding:'6px', color:'white'}}>
                                                    Thank you!
                                                </Typography>
                                            </Card> 
                                        ) : (
                                            <Card>
                                                <Button
                                                    aria-describedby={expiredAriaInfo}
                                                    variant="contained"
                                                    color="highlight"
                                                    sx={{
                                                        width:'100%',
                                                    }}
                                                    onClick={(event) => {
                                                        setAnchorEl(event.currentTarget);
                                                    }}               
                                                >
                                                    <Typography>Job expired?</Typography>
                                                    
                                                </Button>
                                                <Popover
                                                    id={expiredAriaInfo}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={() => {setAnchorEl(null)}}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    
                                                
                                                >
                                                    <Box
                                                        sx={{
                                                            display:'flex',
                                                            flexDirection:'column',
                                                            alignItem:'center',
                                                            justfiyContent:'center',
                                                            padding:'20px'
                                                        }}
                                                    >
                                                        <Typography sx={{ textAlign:'center', paddingBottom:'10px' }}>If this job is expired please click here to let the team know!</Typography>
                                                        <Button
                                                            variant="contained"
                                                            color="highlight"
                                                            sx={{
                                                                width:'40%',
                                                                marginX:'auto'
                                                            }}
                                                            onClick={
                                                                () => {
                                                                    handleExpiredReport();
                                                                }
                                                            }
                                                        >
                                                            Notify the team
                                                        </Button>
                                                    </Box>
                                                    
                                                </Popover>
                                            </Card>                                    
                                        )}
                                    </>                                                                        
                                )}
                            </>
                        )}
                        {loading ? (
                            <Skeleton variant="rectangular" height={100} />
                        ) : job?.can_edit && !(job.paid || user?.premium) ? (
                            <Card>
                                <Button
                                    variant="contained"
                                    startIcon={<ShoppingCartRoundedIcon />}
                                    sx={{ width: "100%" }}
                                    onClick={() => {
                                        axiosInstance
                                            .post(
                                                `/api/stripe/create-checkout-session/job/${job.id}/`
                                            )
                                            .then((response) => {
                                                window.location = response.data.url;
                                            });
                                    }}
                                >
                                    Unlock premium features
                                </Button>
                            </Card>
                        ) : null}
                        {isStaff() ? (
                            <Card sx={{ padding: "10px", textAlign: "center" }}>
                                <Typography> You are staff</Typography>
                                <Divider />
                                <Typography mt={1}> Embed link</Typography>
                                <FormControl variant="outlined">
                                    <OutlinedInput
                                        value={embedLink}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Tooltip title="Copy to clipboard">
                                                    <IconButton
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                embedLink
                                                            );
                                                            enqueueSnackbar("Link copied", {
                                                                variant: "success",
                                                            });
                                                        }}
                                                        edge="end"
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <Typography mt={2}> Embed image</Typography>
                                <img
                                    src={`/api/jobs/${job?.id}/embed_image/`}
                                    style={{ width: "100%", marginBottom: "10px" }}
                                />
                                <JobBufferDialog job={job} />
                            </Card>
                        ) : null}
                        {job?.can_edit && (job.paid || user?.premium) ? (
                            <React.Fragment>
                                {/* TODO Add warning message if adblock is detected */}
                                <Card sx={{ padding: "10px", textAlign: "center" }}>
                                    <Typography> This is a premium job </Typography>
                                </Card>
                                <Card>
                                    <Stack
                                        spacing={5}
                                        direction="row"
                                        sx={{
                                            placeContent: "center",
                                            padding: "10px",
                                        }}
                                    >
                                        <LinkedinShareButton url={embedUrl}>
                                            <LinkedinIcon size={32} round />
                                        </LinkedinShareButton>
                                        <TwitterShareButton url={embedUrl} title={job.title}>
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                        <FacebookShareButton url={embedUrl} quote={job.title}>
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                    </Stack>
                                </Card>
                            </React.Fragment>
                        ) : null}
                        {job?.can_edit &&
                        (job.paid || user?.premium || isStaff()) &&
                        job.posted_date !== null ? (
                            <>
                                <Card sx={{ overflow: "visible" }}>
                                    <JobAnalysis job={job} />
                                </Card>
                                <Card sx={{ overflow: "visible" }}>
                                    <Analytics job={job} />
                                </Card>
                            </>
                        ) : null}
                    </Stack>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default JobPage;
