import { useContext, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import { DarkModeContext } from "../App";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import MuiLink from "@mui/material/Link";
import PrivacyPolicyDialog from "../PrivacyPolicyDialog";
import React from "react";
import { SocialIcon } from "react-social-icons";
import Stack from "@mui/material/Stack";
import TermsAndConditionsDialog from "../TermsAndConditionsDialog";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router";
import { useTheme } from "@mui/material/styles";

const FooterLink = ({ to, children, underline = false, ...props }) => {
    const theme = useTheme();
    const { darkMode, setDarkMode } = useContext(DarkModeContext);

    return (
        <Typography
            color={darkMode ? theme.palette.text.primary : theme.palette.secondary.main}
            component={to ? Link : MuiLink}
            to={to ? to : undefined}
            sx={{
                textDecoration: underline ? "underline" : "none",
                "&:hover": { textDecoration: "underline" },
                cursor: "pointer",
            }}
            {...props}
        >
            {children}
        </Typography>
    );
};

const SocialIcons = (props) => {
    const theme = useTheme();
    const socialIconSize = "68px";
    const iconStyle = {
        width: socialIconSize,
        height: socialIconSize,
        margin: "0px",
    };
    const { darkMode, setDarkMode } = useContext(DarkModeContext);
    const iconColor = useMemo(() => {
        if (darkMode) {
            return theme.palette.text.primary;
        } else {
            return theme.palette.secondary.main;
        }
    }, [darkMode]);

    return (
        <Stack
            justifyContent="right"
            direction="row"
            spacing={2}
            sx={{
                "& .social-svg-mask": { fill: "transparent !important" },
                "& .social-svg:hover > .social-svg-icon": {
                    fill: `${theme.palette.highlight.main} !important`,
                },
            }}
            {...props}
        >
            <SocialIcon
                url="https://www.linkedin.com/company/spacecareers-uk/"
                fgColor={iconColor}
                style={iconStyle}
            />
            <SocialIcon
                url="https://www.twitter.com/spacecareersuk/"
                fgColor={iconColor}
                style={iconStyle}
            />
            <SocialIcon
                url="https://www.instagram.com/spacecareersuk/"
                fgColor={iconColor}
                style={iconStyle}
            />
            <SocialIcon
                url="https://www.facebook.com/spacecareersuk/"
                fgColor={iconColor}
                style={iconStyle}
            />
        </Stack>
    );
};

const HorizontalFooter = ({ setCopyrightOpen, setTermsOpen, setPrivacyOpen }) => {
    const theme = useTheme();
    const { darkMode, setDarkMode } = useContext(DarkModeContext);
    const textColor = useMemo(() => {
        if (darkMode) {
            return theme.palette.text.primary;
        } else {
            return theme.palette.secondary.main;
        }
    }, [darkMode]);

    return (
        <Grid
            container
            p={2}
            spacing={{ lg: 8, xs: 4 }}
            sx={{
                display: { xs: "none", sm: "flex" },
            }}
        >
            <Grid item xs={3} p={2} pb={0}>
                <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
                    <img
                        style={{ padding: "20px", maxWidth: "280px" }}
                        src={
                            darkMode
                                ? "/logos/New Logo Colour_1@4x.png"
                                : "/logos/New Logo White_1@4x.png"
                        }
                        alt="SCUK logo"
                    />
                    <Stack alignSelf="flex-end" sx={{ marginTop: "auto !important" }}>
                        <Typography paragraph color={textColor}>
                            We are a not-for-profit, registered as a company limited by guarantee,
                            No. 14547249
                        </Typography>
                        <Typography color={textColor}>© SpaceCareers.uk Limited 2025</Typography>
                    </Stack>
                </Stack>
            </Grid>

            <Grid item xs={6}>
                <Stack direction="row" spacing={{ lg: 24, xs: 6 }}>
                    <Stack direction="column" sx={{ paddingLeft: "58px" }}>
                        <Typography fontSize="1.34rem" color={textColor} paragraph>
                            For Candidates
                        </Typography>
                        <FooterLink to="/jobs">Jobs</FooterLink>
                        <FooterLink to="/articles/advice_and_information">
                            Advice & Information
                        </FooterLink>
                        <FooterLink to="/articles/job_profiles">Job profiles</FooterLink>
                        <FooterLink to="/articles/career_stories">Career Stories</FooterLink>
                        <FooterLink to="/events">Events</FooterLink>
                        <FooterLink to="/employers">Company A-Z</FooterLink>
                        <FooterLink to="/profile">Create your profile</FooterLink>
                        <FooterLink to="/login">Login</FooterLink>
                    </Stack>
                    <Stack direction="column">
                        <Typography fontSize="1.34rem" color={textColor} paragraph>
                            For Employers
                        </Typography>
                        <FooterLink to="/advertise">Advertise</FooterLink>
                        <FooterLink to="/signup">List your organisation</FooterLink>
                        <FooterLink to="/login">Login</FooterLink>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={3} sx={{ mt: 2 }} justifyContent="center">
                    <FooterLink to="/about">About</FooterLink>
                    <FooterLink to="/contact-us">Contact</FooterLink>
                    <FooterLink onClick={setTermsOpen}>Terms</FooterLink>
                    <FooterLink onClick={setPrivacyOpen}>Privacy</FooterLink>
                    <FooterLink onClick={setCopyrightOpen}>Images</FooterLink>
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
                    <SocialIcons />

                    <Stack
                        alignSelf="flex-end"
                        sx={{
                            marginTop: "auto !important",
                            paddingBottom: { md: "0px", xs: "40px" },
                        }}
                    >
                        <Typography color={textColor} align="right" paragraph>
                            In partnership with
                        </Typography>
                        <img
                            style={{ width: "200px" }}
                            src={darkMode ? "/ukseds-logo-dark.png" : "/ukseds-logo.png"}
                            alt="UKSEDS logo"
                        />
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
};

const VerticalFooter = ({ setCopyrightOpen, setTermsOpen, setPrivacyOpen }) => {
    const theme = useTheme();
    const { darkMode, setDarkMode } = useContext(DarkModeContext);
    const textColor = useMemo(() => {
        if (darkMode) {
            return theme.palette.text.primary;
        } else {
            return theme.palette.secondary.main;
        }
    }, [darkMode]);

    return (
        <Stack
            p={4}
            spacing={{ lg: 8, xs: 4 }}
            sx={{
                display: { xs: "flex", sm: "none" },
            }}
        >
            <SocialIcons justifyContent="center" />
            <Stack direction="column">
                <Typography fontSize="1.34rem" color={textColor} paragraph>
                    For Candidates
                </Typography>
                <FooterLink to="/jobs">Jobs</FooterLink>
                <FooterLink to="/articles/advice_and_information">Advice & Information</FooterLink>
                <FooterLink to="/articles/job_profiles">Job profiles</FooterLink>
                <FooterLink to="/articles/career_stories">Career Stories</FooterLink>
                <FooterLink to="/events">Events</FooterLink>
                <FooterLink to="/employers">Company A-Z</FooterLink>
                <FooterLink to="/profile">Create your profile</FooterLink>
                <FooterLink to="/login">Login</FooterLink>
            </Stack>
            <Stack direction="column">
                <Typography fontSize="1.34rem" color={textColor} paragraph>
                    For Employers
                </Typography>
                <FooterLink to="/advertise">Advertise</FooterLink>
                <FooterLink to="/signup">List your organisation</FooterLink>
                <FooterLink to="/login">Login</FooterLink>
            </Stack>
            <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
                <img
                    style={{ padding: "20px", maxWidth: "280px" }}
                    src={
                        darkMode
                            ? "/logos/New Logo Colour_1@4x.png"
                            : "/logos/New Logo White_1@4x.png"
                    }
                    alt="SCUK logo"
                />
                <Stack alignSelf="flex-end" sx={{ marginTop: "auto !important" }}>
                    <Typography paragraph color={textColor}>
                        We are a not-for-profit, registered as a company limited by guarantee, No.
                        14547249
                    </Typography>
                    <Typography color={textColor}>© SpaceCareers.uk Limited 2023</Typography>
                </Stack>
            </Stack>
            <Stack>
                <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
                    <FooterLink to="/about">About</FooterLink>
                    <FooterLink to="/contact-us">Contact</FooterLink>
                    <FooterLink onClick={setTermsOpen}>Terms</FooterLink>
                </Stack>
                <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
                    <FooterLink onClick={setPrivacyOpen}>Privacy</FooterLink>
                    <FooterLink onClick={setCopyrightOpen}>Images</FooterLink>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
                <Stack alignSelf="flex-start">
                    <Typography color={textColor} paragraph>
                        In partnership with
                    </Typography>
                    <img
                        style={{ width: "200px" }}
                        src={darkMode ? "/ukseds-logo-dark.png" : "/ukseds-logo.png"}
                        alt="UKSEDS logo"
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

const Footer = (bgLanding=false,) => {
    const theme = useTheme();
    const [copyrightOpen, setCopyrightOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);
    const { darkMode, setDarkMode } = useContext(DarkModeContext);

    return (
        <Box
            sx={{
                backgroundColor: bgLanding?'#1A305B':darkMode ? "white" : theme.palette.text.primary,
                display: "flex",
            }}
        >
            <Box sx={{ width: "100%", margin: { xs: "10px", md: "35px" } }}>
                <HorizontalFooter
                    setCopyrightOpen={setCopyrightOpen}
                    setTermsOpen={setTermsOpen}
                    setPrivacyOpen={setPrivacyOpen}
                />
                <VerticalFooter
                    setCopyrightOpen={setCopyrightOpen}
                    setTermsOpen={setTermsOpen}
                    setPrivacyOpen={setPrivacyOpen}
                />
            </Box>
            <ImageCreditDialog open={copyrightOpen} onClose={() => setCopyrightOpen(false)} />
            <TermsAndConditionsDialog open={termsOpen} onClose={() => setTermsOpen(false)} />
            <PrivacyPolicyDialog open={privacyOpen} onClose={() => setPrivacyOpen(false)} />
        </Box>
    );
};

const ImageCreditDialog = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} p={2}>
            <DialogContent>
                <Typography variant="h4" align="center" paragraph>
                    Attributions
                </Typography>
                <Typography variant="h5" paragraph>
                    Home
                </Typography>
                <ListItem sx={{ display: "list-item" }}>
                    Sea Of Stars. Photo by{" "}
                    <a href="https://unsplash.com/@jeremyperkins?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                        Jeremy Perkins
                    </a>
                    {" on "}
                    <a href="https://unsplash.com/photos/uhjiu8FjnsQ?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                        Unsplash
                    </a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Ocean clouds seen from space. Photo by{" "}
                    <a href="https://unsplash.com/@nasa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                        NASA
                    </a>{" "}
                    on{" "}
                    <a href="https://unsplash.com/photos/yZygONrUBe8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                        Unsplash
                    </a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Laptops on table. Photo by{" "}
                    <a href="https://unsplash.com/@marvelous?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                        Marvin Meyer
                    </a>{" "}
                    on{" "}
                    <a href="https://unsplash.com/photos/SYTO3xs06fU?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                        Unsplash
                    </a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    {" "}
                    National Student Space Conference 2019. Photo by UKSEDS
                </ListItem>
                <Typography variant="h5" paragraph>
                    About
                </Typography>
                <ListItem sx={{ display: "list-item" }}>
                    Careers Launch Goonhilly 2019. Photo by UKSEDS
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Connection icon from <a href="https://www.flaticon.com/">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Education icon from <a href="http://flaticon.com/">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Support icon from <a href="http://flaticon.com/">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Advocacy icon from <a href="http://flaticon.com/">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Accessibility icon from <a href="http://flaticon.com/">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Openness and Collaboration icon from{" "}
                    <a href="http://flaticon.com/">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Commitment and Quality icon from <a href="http://flaticon.com/">Flaticon.com</a>
                </ListItem>
                <Typography variant="h5" paragraph>
                    Resources
                </Typography>
                <ListItem sx={{ display: "list-item" }}>
                    {" "}
                    Gray and white robot. Photo by{" "}
                    <a href="https://www.pexels.com/photo/gray-and-white-robot-73910/">Pixabay</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    American astronaut in space. Photo by{" "}
                    <a href="https://www.pexels.com/photo/sky-earth-space-working-2156/">Pixabay</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Female space operations engineer in space tunnel. Photo by{" "}
                    <a href="https://unsplash.com/@thisisengineering">ThisisEngineering RAEng</a> on{" "}
                    <a href="https://unsplash.com/photos/Bt9HIKC0Nus?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                        Unsplash
                    </a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    National Student Space Conference 2019. Photo by UKSEDS
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Photo of night sky. Photo by{" "}
                    <a href="https://www.pexels.com/photo/photo-of-night-sky-1025469/">Faik Akmd</a>
                </ListItem>
                <Typography variant="h5" paragraph>
                    Advertise
                </Typography>
                <ListItem sx={{ display: "list-item" }}>
                    Magnifying glass icon from{" "}
                    <a href="https://www.flaticon.com/authors/muhammad-usman">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Candidate matching icon from{" "}
                    <a href="https://www.flaticon.com/authors/parzival-1997">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Email icon from{" "}
                    <a href="https://www.flaticon.com/authors/those-icons">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Profile icon from{" "}
                    <a href="https://www.flaticon.com/authors/bingge-liu">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Upload icon from{" "}
                    <a href="https://www.flaticon.com/authors/kiranshastry">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Thumbs up icon from{" "}
                    <a href="https://www.flaticon.com/authors/freepik">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Graph icon from{" "}
                    <a href="https://www.flaticon.com/authors/freepik">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    {" "}
                    Astronaut icon from{" "}
                    <a href="https://www.flaticon.com/authors/futuer">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Earth icon from{" "}
                    <a href="https://www.flaticon.com/authors/freepik">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Not-for-profit icon from{" "}
                    <a href="https://www.flaticon.com/authors/dinosoftlabs">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    Well established icon from{" "}
                    <a href="https://www.flaticon.com/authors/uniconlabs">Flaticon.com</a>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                    UK map icon created with <a href="https://www.mapchart.net/">MapChart.net</a>
                </ListItem>
            </DialogContent>
        </Dialog>
    );
};

export default Footer;
