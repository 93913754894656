import React, { useCallback, useState } from "react";
import { format as formatDate, parse } from "date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Badge from "@mui/material/Badge";
import Box from "@mui/system/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DoneIcon from "@mui/icons-material/Done";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MDEditor from "@uiw/react-md-editor";
import MarkdownEditor from "./MarkdownEditor";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import UndoIcon from "@mui/icons-material/Undo";
import rehypeSanitize from "rehype-sanitize";
import { useTheme } from "@mui/material/styles";

export const DurationEditor = ({ value, onChange }) => {
    const setJobDurationValue = useCallback(
        (key, newValue) => {
            onChange({ ...value, [key]: newValue });
        },
        [value, onChange]
    );
    return (
        <Stack spacing={1} direction="row">
            <TextField
                sx={{ width: "70px" }}
                size="small"
                label="Years"
                variant="filled"
                color="primary"
                type="number"
                InputProps={{
                    inputProps: {
                        max: 100,
                        min: 0,
                    },
                }}
                value={value?.years || 0}
                onChange={(e) => setJobDurationValue("years", e.target.value)}
                disabled={value?.permanent}
            />
            <TextField
                sx={{ width: "70px" }}
                size="small"
                label="Months"
                variant="filled"
                color="primary"
                type="number"
                InputProps={{
                    inputProps: {
                        max: 11,
                        min: 0,
                    },
                }}
                value={value?.months || 0}
                onChange={(e) => setJobDurationValue("months", e.target.value)}
                disabled={value?.permanent}
            />
            <TextField
                sx={{ width: "70px" }}
                size="small"
                label="Weeks"
                variant="filled"
                color="primary"
                type="number"
                InputProps={{
                    inputProps: {
                        max: 3,
                        min: 0,
                    },
                }}
                value={value?.weeks || 0}
                onChange={(e) => setJobDurationValue("weeks", e.target.value)}
                disabled={value?.permanent}
            />
            <FormControlLabel
                control={<Switch color="primary" />}
                label="Permanent"
                checked={value?.permanent}
                onChange={(e) => setJobDurationValue("permanent", e.target.checked)}
            />
        </Stack>
    );
};

export const ShortDurationEditor = ({ value, onChange }) => {
    const setJobDurationValue = useCallback(
        (key, newValue) => {
            onChange({ ...value, [key]: newValue });
        },
        [value, onChange]
    );
    return (
        <Stack spacing={1} direction="row">
            <TextField
                sx={{ width: "70px" }}
                size="small"
                label="Weeks"
                variant="filled"
                color="primary"
                type="number"
                InputProps={{
                    inputProps: {
                        max: 4,
                        min: 0,
                    },
                }}
                value={value?.weeks || 0}
                onChange={(e) => setJobDurationValue("weeks", parseInt(e.target.value))}
            />
            <TextField
                sx={{ width: "70px" }}
                size="small"
                label="Days"
                variant="filled"
                color="primary"
                type="number"
                InputProps={{
                    inputProps: {
                        max: 31,
                        min: 0,
                    },
                }}
                value={value?.days || 0}
                onChange={(e) => setJobDurationValue("days", parseInt(e.target.value))}
            />
            <TextField
                sx={{ width: "70px" }}
                size="small"
                label="Hours"
                variant="filled"
                color="primary"
                type="number"
                InputProps={{
                    inputProps: {
                        max: 23,
                        min: 0,
                    },
                }}
                value={value?.hours || 0}
                onChange={(e) => setJobDurationValue("hours", parseInt(e.target.value))}
            />
        </Stack>
    );
};

const EditableTextField = ({
    value,
    onChange,
    children,
    variant = "body1",
    sx = {},
    editable = false,
    markdown = false,
    color = "text",
    selectValues = undefined,
    datePicker = false,
    InputProps = {},
    customSelectValuesDisplay = false,
    dirty = false,
    format = (x) => x,
    tooltip = "",
    markdownEditorExclude = [],
    durationEditor = false,
    shortDurationEditor = false,
    ...rest
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const theme = useTheme();

    const renderBody = useCallback(() => {
        if (isEditing) {
            if (markdown) {
                return (
                    <Box>
                        <MarkdownEditor
                            data={value}
                            onChange={(event, editor) => onChange(editor.getData())}
                            exclude={markdownEditorExclude}
                        />
                    </Box>
                );
            } else if (selectValues) {
                return (
                    <TextField
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        margin="normal"
                        fullWidth
                        variant="standard"
                        autoFocus={true}
                        sx={{
                            padding: 0,
                            margin: 0,
                        }}
                        color="primary"
                        select
                        InputProps={InputProps}
                    >
                        {selectValues.map((option) => (
                            <MenuItem key={option[0]} value={option[0]}>
                                {option[1]}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            } else if (datePicker) {
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            mask="__-__-____"
                            renderInput={(params) => <TextField {...params} />}
                            value={
                                value
                                    ? parse(value, "dd-MM-yyyy", new Date())
                                    : null
                            }
                            onChange={(newValue) => {
                                try {
                                    onChange(formatDate(newValue, "dd-MM-yyyy"));
                                } catch (e) {
                                    onChange(null);
                                }
                            }}
                            inputFormat="dd-MM-yyyy"
                        />
                    </LocalizationProvider>
                );
            } else if (durationEditor) {
                return <DurationEditor value={value} onChange={onChange} />;
            } else if (shortDurationEditor) {
                return <ShortDurationEditor value={value} onChange={onChange} />;
            } else {
                return (
                    <TextField
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        margin="normal"
                        fullWidth
                        variant="standard"
                        autoFocus={true}
                        sx={{
                            padding: 0,
                            margin: 0,
                            "& .MuiInput-root": { padding: 0, margin: 0 },
                        }}
                        inputProps={{
                            style: {
                                color: theme.palette[color].main,
                                ...theme.typography[variant],
                                ...sx,
                            },
                        }}
                        InputProps={InputProps}
                        multiline
                        color={color}
                    />
                );
            }
        } else {
            if (markdown) {
                return (
                    <MDEditor.Markdown
                        source={value}
                        rehypePlugins={[[rehypeSanitize]]}
                        className={editable ? "editable-text-field" : ""}
                        {...rest}
                    />
                );
            } else if (children) {
                return <span {...rest}>{children}</span>;
            } else if (selectValues && customSelectValuesDisplay) { // the 3rd element of the selectValues array is the display value
                return (
                    <Typography variant={variant} color={color} sx={sx} {...rest}>
                        {format(selectValues.filter((option) => option[0] === value)[0]?.[2])} 
                    </Typography>
                );
            } else if (selectValues) {
                return (
                    <Typography variant={variant} color={color} sx={sx} {...rest}>
                        {format(selectValues.filter((option) => option[0] === value)[0]?.[1])}
                    </Typography>
                );
            } else {
                return (
                    <Typography variant={variant} color={color} sx={sx} {...rest}>
                        {format(value)}
                    </Typography>
                );
            }
        }
    }, [
        InputProps,
        children,
        color,
        datePicker,
        editable,
        markdown,
        onChange,
        selectValues,
        sx,
        theme,
        value,
        variant,
        isEditing,
    ]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl) && isEditing;
    const handleClose = () => {
        setAnchorEl(null);
    };

    const isClickTargetInCKeditorPopup = (target) => {
        return Array.from(document.getElementsByClassName("ck-body-wrapper")).some((n) =>
            n.contains(target)
        );
    };

    return (
        <ClickAwayListener
            onClickAway={(e) => {
                if (!isClickTargetInCKeditorPopup(e.target)) {
                    setIsEditing(false);
                }
            }}
            mouseEvent="onMouseDown"
        >
            <span
                onClick={(event) => {
                    if (editable && !isEditing) {
                        setIsEditing(editable);
                        setAnchorEl(event.currentTarget);
                    }
                }}
                style={{
                    display: "block",
                    ...(editable &&
                    (value === undefined ||
                        value === null ||
                        value === "" ||
                        format(value).trim() === "")
                        ? {
                              minHeight: "1em",
                              borderStyle: "dashed",
                              borderWidth: "thin",
                              minWidth: "50px",
                          }
                        : {}),
                }}
                className={editable && !isEditing ? "editable-text-field" : ""}
            >
                <React.Fragment>
                    <Tooltip title={editable ? tooltip : ""}>
                        <Badge
                            color="primary"
                            invisible={!dirty}
                            sx={{ display: "block", minHeight: "24px" }}
                            variant="dot"
                        >
                            <span>{renderBody()}</span>
                        </Badge>
                    </Tooltip>
                    <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
                        <Box sx={{ bgcolor: "background.paper" }}>
                            <ButtonGroup variant="contained">
                                <IconButton
                                    onClick={(event) => {
                                        setIsEditing(false);
                                        event.stopPropagation();
                                    }}
                                >
                                    <Tooltip title="Done">
                                        <DoneIcon />
                                    </Tooltip>
                                </IconButton>
                                <IconButton
                                    disabled={!dirty}
                                    onClick={(event) => {
                                        onChange(undefined);
                                        setIsEditing(false);
                                        event.stopPropagation();
                                    }}
                                >
                                    <Tooltip title="Undo">
                                        <UndoIcon />
                                    </Tooltip>
                                </IconButton>
                            </ButtonGroup>
                        </Box>
                    </Popper>
                </React.Fragment>
            </span>
        </ClickAwayListener>
    );
};
export default EditableTextField;
