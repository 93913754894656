import React, { useCallback, useEffect, useMemo, useState } from "react";

import AlarmIcon from "@mui/icons-material/Alarm";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Container from "@mui/material/Container";
import Dropzone from "../common/Dropzone";
import EditIcon from "@mui/icons-material/Edit";
import EditableTextField from "../common/EditableTextField";
import Grid from "@mui/material/Grid";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PersonIcon from '@mui/icons-material/Person';
import Link from "@mui/material/Link";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import SaveIcon from "@mui/icons-material/Save";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import UndoIcon from "@mui/icons-material/Undo";
import axiosInstance from "../axiosInstance";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { usePrompt } from "../common/ReactRouterBlocker";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import { parse, format } from 'date-fns';

const SKILLS_EVENT_TYPES = [
    ["CAREERS FAIR","Careers Fair"],
    ["CONFERENCE","Conference"],
    ["COURSE","Course"],
    ["HACKATHON","Hackathon"],
    ["SOCIAL","Social"],
    ["SUMMER SCHOOL","Summer School"],
    ["TALK","Talk"],
    ["WORKSHOP","Workshop"],
    ["COMPETITION","Competition"]
]

const SkillEventEditableTextField = ({ field, edits, skillEvent, setEdits, format, selectValues, icon, tooltip, variant, ...rest }) => {
    var value = field in edits ? edits[field] : skillEvent[field]
    return (
        <>
        {icon ? (
            <Box sx={{ display: "flex", flexDirection: "row", paddingLeft: "5px" }}>
                <Tooltip title={tooltip}>
                    {React.cloneElement(icon, {
                        color: "text",
                        sx: { fontSize: "1.9rem", marginRight: "2px" }
                    })}
                </Tooltip>
                <EditableTextField
                    format={format}
                    value={value}
                    onChange={(value) => {
                        setEdits({ ...edits, [field]: value });
                    }}
                    dirty={edits?.[field] !== skillEvent[field] && edits?.[field] !== undefined}
                    variant={variant ? variant : "h4"}
                    color="primary"
                    editable={skillEvent?.can_edit}
                    selectValues={selectValues}
                    {...rest}
                />
            </Box>
        ) : (
            <EditableTextField
                format={format}
                value={value}
                onChange={(value) => {
                    setEdits({ ...edits, [field]: value });
                }}
                dirty={edits?.[field] !== skillEvent[field] && edits?.[field] !== undefined}
                variant={variant ? variant : "h4"}
                color="primary"
                editable={skillEvent?.can_edit}
                selectValues={selectValues}
                {...rest}
            />
            )}
        </>
    );
};

const SkillEventImage = ({ skillEvent, edits, setEdits, smallScreen }) => {
    return (
        <Dropzone
        imageProps={{
            style: {
                width: smallScreen ? "100%" : "300px",
                height: "300px",
                    objectFit: "contain",
                    margin: "auto",
                    display: "block",
                },
                src: skillEvent.image,
                alt: "Skill Event image",
            }}
            editable={skillEvent?.can_edit}
            onChange={(file) => {
                setEdits({ ...edits, image: file });
            }}
            /> 
    );
};

const SkillEventPage = ({ addBannerNotification }) => {
    const theme = useTheme();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let params = useParams();
    const [skillEvent, setSkillEvent] = useState({});
    const [edits, setEdits] = useState({});
    const [error404, setError404] = useState(false);
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [loading, setLoading] = useState(true); // to implement loading skeleton
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        if (skillEvent.can_edit) {
        axiosInstance.get("/api/skillevents/countries/").then((response) => {
            setCountries(response.data);
        });
        }
    }, [skillEvent]);

    const isDirty = useMemo(() => {
        return (
            Object.keys(edits).length > 0 &&
            Object.keys(edits).some((key) => edits[key] !== skillEvent[key])
        );
    }, [edits, skillEvent]);

    usePrompt(
        "Your changes have not been saved. Are you sure you want to leave this page?",
        isDirty
    );

    useEffect(() => {
        axiosInstance
            .get(`/api/skillevents/${params.eventId}/`)
            .then((response) => {
                setSkillEvent(response.data);
                setLoading(false);
                window.posthog.capture("Saw skill event page", {
                    eventId: params.eventId,
                });
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    setError404(true);
                }
            });
    }, [params.eventId]);

    const submitEdits = useCallback(() => {
        let formData = new FormData();
  
        if (edits.image) { formData.append("image", edits.image); }
        if (edits.duration) { formData.append("duration", JSON.stringify(edits.duration)); }

        Object.entries(edits).forEach(([key, value]) => {
            if (key === "image" || key === "duration") return;
            formData.append(key, value);
        });

        axiosInstance
            .patch(`/api/skillevents/${params.eventId}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                setSkillEvent(response.data);
                setEdits({});
                enqueueSnackbar("Skill event updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating Skill event.", { variant: "error" });
            });
    }, [edits, enqueueSnackbar, params.eventId]);

    useEffect(() => {
        addBannerNotification(
            skillEvent?.can_edit ? "You can edit this skill event." : undefined,
            "ownership"
        );
        return () => {
            addBannerNotification(undefined, "ownership");
        };
    }, [skillEvent, addBannerNotification]);

    useEffect(() => {
        addBannerNotification(
            isDirty ? (
                <Typography align="center">
                    You have unsaved changes.
                    <Link
                        onClick={submitEdits}
                        sx={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: theme.palette.highlight.main,
                        }}
                    >
                        Save changes
                    </Link>
                </Typography>
            ) : undefined,
            "dirty"
        );
        return () => {
            addBannerNotification(undefined, "dirty");
        };
    }, [isDirty, submitEdits, addBannerNotification]);

    useEffect(() => {
        addBannerNotification(
            skillEvent?.can_edit ? "You can edit this skill event." : undefined,
            "ownership"
        );
        return () => {
            addBannerNotification(undefined, "ownership");
        };
    }, [skillEvent, addBannerNotification]);

    const makeSkillEventPublic = () => {
        axiosInstance
            .patch(`/api/skillevents/${params.eventId}/`, { private: false })
            .then((response) => {
                setSkillEvent(response.data);
                enqueueSnackbar("Skill Event updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating skill event.", { variant: "error" });
            });
    };

    const makeSkillEventPrivate = () => {
        axiosInstance
            .patch(`/api/skillevents/${params.eventId}/`, { private: true })
            .then((response) => {
                setSkillEvent(response.data);
                enqueueSnackbar("Skill Event updated successfully.", { variant: "success" });
            })
            .catch((err) => {
                enqueueSnackbar("Error updating skill event.", { variant: "error" });
            });
    };

    const makePublicAction = (snackbarId) => (
        <>
            <Button size="small" color="secondary" onClick={makeSkillEventPublic}>
                Make public
            </Button>
        </>
    );

    useEffect(() => {
        if (skillEvent?.private) {
            const key = enqueueSnackbar("This skill event is private.", {
                action: makePublicAction,
                preventDuplicate: false,
                persist: true,
                variant: "warning",
            });
            return () => {
                closeSnackbar(key);
            };
        }
    }, [skillEvent, closeSnackbar, enqueueSnackbar]);

    useEffect(() => {
        // remove any keys from edits where the value is undefined
        if (Object.values(edits).some((e) => e === undefined)) {
            setEdits((oldEdits) => {
                let newEdits = { ...oldEdits };
                Object.keys(newEdits).forEach((key) => {
                    if (newEdits[key] === undefined) {
                        delete newEdits[key];
                    }
                });
                return newEdits;
            });
        }
    }, [edits]);

    var pluralize = require("pluralize");

    const pluralizeOrOmit = (noun, count, addTrailingSpace = false) => {
        if (!count) {
            return "";
        } else {
            return pluralize(noun, count, true) + (addTrailingSpace ? " " : "");
        }
    };

    const formatDuration = (duration) => {  
        return (
            pluralizeOrOmit("week", duration?.weeks, true) +
            " " +
            pluralizeOrOmit("day", duration?.days, true) +
            " " +
            pluralizeOrOmit("hour", duration?.hours)
        );
    };

    const formatCapitalised = (value) => {
        if (typeof(value) == "string") {
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }   
    }

    const formatDate = (date) => {
        if (date) {
            let dateObj = parse(date, "dd-MM-yyyy", new Date())
            return format(dateObj, "do MMMM yyyy")
        } else {
            return ""
        }
    }
    return (
        <PageContainer error404={error404}>
            {skillEvent?.can_edit ? (
                <SpeedDial
                    sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 10 }}
                    ariaLabel="SpeedDial"
                    icon={<EditIcon />}
                >
                    <SpeedDialAction
                        key={"save"}
                        icon={<SaveIcon />}
                        tooltipTitle={"Save"}
                        onClick={submitEdits}
                        disabled={!isDirty}
                    />

                    {skillEvent.private ? (
                        <SpeedDialAction
                            key={"public"}
                            icon={<LockOpenIcon />}
                            tooltipTitle={"Make public"}
                            onClick={makeSkillEventPublic}
                        />
                    ) : (
                        <SpeedDialAction
                            key={"private"}
                            icon={<LockIcon />}
                            tooltipTitle={"Make private"}
                            onClick={makeSkillEventPrivate}
                        />
                    )}
                    <SpeedDialAction
                        key={"reset"}
                        icon={<UndoIcon />}
                        tooltipTitle={"Reset edits"}
                        onClick={() => setEdits({})}
                        disabled={!isDirty}
                    />
                </SpeedDial>
            ) : null}
            <Breadcrumbs
                color={theme.palette.text.primary}
                separator={<NavigateNextIcon fontSize="small" />}
            >
                <Link underline="hover" color="inherit" href="/home">
                    Home
                </Link>
                <Link underline="hover" color="inherit" href="/skills/">
                    Skills
                </Link>
                <Typography color="inherit">{skillEvent.name}</Typography>
            </Breadcrumbs>
            <Box>
                <Paper sx={{ padding: "20px", display: "flex", flexDirection: {xs:"column", md:"row"} }}>
                    <Stack sx={{ flexGrow:1, minHeight: "300px" }}>                          
                        <SkillEventEditableTextField
                            edits={edits}
                            field="name"
                            setEdits={setEdits}
                            skillEvent={skillEvent}
                            tooltip="Skill Event Name"
                            variant="h3"
                        />
                        {skillEvent.date || skillEvent.can_edit ? (
                            <SkillEventEditableTextField
                                format={formatDate}
                                field="date"
                                edits={edits}
                                setEdits={setEdits}
                                skillEvent={skillEvent}
                                tooltip="Skill Event date"
                                variant="h4"
                                color="text"
                                datePicker
                            />
                        ) : null}
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            {skillEvent.city || skillEvent.can_edit ? (
                                <>
                                    <SkillEventEditableTextField
                                        field="city"
                                        edits={edits}
                                        setEdits={setEdits}
                                        skillEvent={skillEvent}
                                        tooltip="Skill Event City"
                                        variant="h5"
                                        color="text"
                                    />
                                    <Typography
                                        variant="h5"
                                        color="text"
                                        sx={{ marginLeft: "0 !important" }}
                                        >
                                        ,&nbsp;
                                    </Typography>
                                </>
                            ) : null}

                            <SkillEventEditableTextField
                                field={skillEvent.can_edit ? "country" : "country_display"}
                                edits={edits}
                                setEdits={setEdits}
                                skillEvent={skillEvent}
                                tooltip="Skill Event Country"
                                variant="h5"
                                color="text"
                                selectValues={skillEvent.can_edit ? countries : null}
                            /> 
                        </Box>
                        <SkillEventEditableTextField
                            field="type"
                            edits={edits}
                            setEdits={setEdits}
                            skillEvent={skillEvent}
                            tooltip="Skill Event Type"
                            variant="h6"
                            color={"#6A80B4"}
                            format={formatCapitalised}
                            selectValues={SKILLS_EVENT_TYPES}
                        />
                        <Box sx={{ display: "flex", flexGrow: 1 }} />
                        <SkillEventEditableTextField
                            field="price"
                            edits={edits}
                            setEdits={setEdits}
                            skillEvent={skillEvent}
                            tooltip="Ticket Price"
                            variant="h6"
                            color="text"
                            icon={<ConfirmationNumberIcon />}
                        />
                        <SkillEventEditableTextField
                            field="application_deadline"
                            edits={edits}
                            setEdits={setEdits}
                            skillEvent={skillEvent}
                            tooltip="Skill Event Application Deadline"
                            variant="h6"
                            color="text"
                            datePicker
                            icon={<AlarmIcon />}
                        />
                        {skillEvent.duration || skillEvent.can_edit ? (
                            <SkillEventEditableTextField
                                format={formatDuration}
                                field="duration"
                                edits={edits}
                                setEdits={setEdits}
                                skillEvent={skillEvent}
                                tooltip="Skill Event Duration"
                                variant="h6"
                                color="text"
                                shortDurationEditor
                                icon={<HourglassBottomIcon />}
                            />
                        ) : null}
                        <SkillEventEditableTextField
                            field="organiser"
                            edits={edits}
                            setEdits={setEdits}
                            skillEvent={skillEvent}
                            tooltip="Skill Event Organiser"
                            variant="h6"
                            color="text"
                            icon={<PersonIcon />}
                        />
                    </Stack>
                    {skillEvent.image || skillEvent.can_edit ? (
                        <SkillEventImage 
                            skillEvent={skillEvent} 
                            edits={edits} 
                            setEdits={setEdits} 
                            smallScreen={smallScreen}
                        />
                    ) : null}
                </Paper>
            </Box>
            <Grid container spacing={2}>
                <Grid item sm={9} xs={12}>
                    <Paper sx={{ padding: "20px" }}>
                        <EditableTextField
                            value={"description" in edits ? edits.description : skillEvent.description}
                            onChange={(value) => {
                                setEdits({ ...edits, description: value });
                            }}
                            dirty={
                                edits?.description !== skillEvent.description &&
                                edits?.description !== undefined
                            }
                            markdown
                            editable={skillEvent?.can_edit}
                            tooltip="Description"
                        />
                    </Paper>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Stack spacing={2}>
                        {skillEvent.link ? (
                            <Card>
                                <Container disableGutters sx={{ padding: "10px" }}>
                                    <Button
                                        variant="contained"
                                        color="highlight"
                                        href={skillEvent.link}
                                        target="_blank"
                                        sx={{
                                            marginRight: "30px !important",
                                            marginLeft: "30px !important",
                                            width: "calc(100% - 60px)",
                                        }}
                                    >
                                        Find out more
                                    </Button>
                                </Container>
                            </Card>
                        ) : null}
                    </Stack>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default SkillEventPage;
